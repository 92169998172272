import { useDeleteQuery, useGetQuery, usePatchQuery, usePostQuery, useRouteParams } from "./helpers"
import { NewsParams } from "./types"

//--------------------------------------------------------------------------------------------------
// URLs
//--------------------------------------------------------------------------------------------------

/** @returns /manage/user/news/ */
export const getNewsListUrl = () => "/manage/user/news/"

/** @returns /manage/user/news/${newsUuid} */
export const getNewsUrl = ({ newsUuid }: Required<NewsParams>) => `/manage/user/news/${newsUuid}/`

//--------------------------------------------------------------------------------------------------
// Queries & mutations
//--------------------------------------------------------------------------------------------------

export const useNewsList = () => {
  const url = getNewsListUrl()

  return useGetQuery<api.user.PaginatedNewsListList>("user", url)
}

export const useNews = (params?: NewsParams) => {
  const mergedParams = useRouteParams(params)
  const url = getNewsUrl(mergedParams)

  return useGetQuery<api.user.NewsDetail>("user", url)
}

export const useCreateNews = () => {
  const url = getNewsListUrl()

  type TRequest = api.user.NewsDetail
  type TResponse = api.user.NewsDetail

  return usePostQuery<TRequest, TResponse>("user", url, [url])
}

export const useUpdateNews = (params?: NewsParams) => {
  const mergedParams = useRouteParams(params)
  const url = getNewsUrl(mergedParams)
  const listUrl = getNewsListUrl()

  type TRequest = api.user.PatchedNewsDetailRequest
  type TResponse = api.user.NewsDetail

  return usePatchQuery<TRequest, TResponse>("user", url, [listUrl])
}

export const useDeleteNews = (params?: NewsParams) => {
  const mergedParams = useRouteParams(params)
  const url = getNewsUrl(mergedParams)
  const listUrl = getNewsListUrl()

  return useDeleteQuery("user", url, [listUrl])
}
