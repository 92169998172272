/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Navigate, Route } from "@tanstack/react-location"
import { lazy } from "react"
import { NotFoundError } from "src/helpers/errors"
import Main from "./Main"

// Organizations
const OrganizationList = lazy(() => import("./Main/organizations/OrganizationList"))
const OrganizationView = lazy(() => import("./Main/organizations/OrganizationView"))

const BillingTab = lazy(() => import("./Main/organizations/OrganizationView/BillingTab"))
const LinkTab = lazy(() => import("./Main/organizations/OrganizationView/LinkTab"))
const OrganizationTab = lazy(() => import("./Main/organizations/OrganizationView/OrganizationTab"))
const PlayTab = lazy(() => import("./Main/organizations/OrganizationView/PlayTab"))
const PowerVUTab = lazy(() => import("./Main/organizations/OrganizationView/PowerVUTab"))

// Billing
const BillingAccountList = lazy(() => import("./Main/billing/BillingAccountList"))

// Link Pools
const LinkPoolView = lazy(() => import("./Main/link/PoolView"))
const LinkPropertiesTab = lazy(() => import("./Main/link/PoolView/propertiesTab/Properties"))

// Link Inputs
const LinkInputList = lazy(() => import("./Main/link/PoolView/inputsTab/InputList"))
const LinkInputView = lazy(() => import("./Main/link/PoolView/inputsTab/InputView"))

// Link Outputs
const OutputList = lazy(() => import("./Main/link/PoolView/outputsTab/OutputList"))
const OutputView = lazy(() => import("./Main/link/PoolView/outputsTab/OutputView"))

// Play Pools
const PlayPoolView = lazy(() => import("./Main/play/PoolView"))
const PlayPropertiesTab = lazy(() => import("./Main/play/PoolView/propertiesTab/Properties"))

// Play Inputs
const InputsList = lazy(() => import("./Main/play/PoolView/inputsTab/InputsList"))
const InputView = lazy(() => import("./Main/play/PoolView/inputsTab/InputView"))

// Play Streams
const StreamsList = lazy(() => import("./Main/play/PoolView/streamsTab/ListStreams"))
const StreamView = lazy(() => import("./Main/play/PoolView/streamsTab/ViewStream"))

// Play Processings
const ProcessingsList = lazy(() => import("./Main/play/PoolView/processingsTab/ProcessingsList"))
const ProcessingView = lazy(() => import("./Main/play/PoolView/processingsTab/ProcessingView"))

// Play Targets
const TargetsList = lazy(() => import("./Main/play/PoolView/targetsTab/ListTargets"))
const TargetView = lazy(() => import("./Main/play/PoolView/targetsTab/ViewTarget"))

// Users
const UserList = lazy(() => import("./Main/users/UsersList"))
const UserView = lazy(() => import("./Main/users/UserView"))

// API keys
const ApiKeyView = lazy(() => import("../components/features/ApiKeyView"))

// DRM vendors
const DrmProviderView = lazy(() => import("./Main/organizations/DrmProviderView"))

// Operators
const OperatorList = lazy(() => import("./Main/operators/OperatorList"))
const OperatorView = lazy(() => import("./Main/operators/OperatorView"))

// Infrastructure
const DataplaneList = lazy(() => import("./Main/dataplanes/DataplaneList"))
const PlayDataplaneList = lazy(() => import("./Main/dataplanes/DataplaneList/PlayDataplaneList"))
const PlayDataplaneView = lazy(() => import("./Main/dataplanes/DataplaneView/PlayDataplaneView"))
const LinkDataplaneList = lazy(() => import("./Main/dataplanes/DataplaneList/LinkDataplaneList"))
const LinkDataplaneView = lazy(() => import("./Main/dataplanes/DataplaneView/LinkDataplaneView"))
const WorkflowSetView = lazy(() => import("./Main/workflowSet/WorkflowSetView"))

// Teams
const TeamList = lazy(() => import("./Main/teams/TeamList"))
const TeamView = lazy(() => import("./Main/teams/TeamView"))

// Organization groups
const OrganizationGroupList = lazy(() => import("./Main/organizationGroups/OrganizationGroupList"))
const OrganizationGroupView = lazy(() => import("./Main/organizationGroups/OrganizationGroupView"))

// Misc
const Logout = lazy(() => import("src/components/utils/Logout"))

// COGS
const CogsView = lazy(() => import("./Main/cogs/CogsView"))
const CostCogs = lazy(() => import("./Main/cogs/CostCogs"))
const UsageCogs = lazy(() => import("./Main/cogs/UsageCogs"))
const OrgsCogs = lazy(() => import("./Main/cogs/OrgsCogs"))

// Logs
const LogList = lazy(() => import("./Main/logs/LogList"))

// News
const NewsList = lazy(() => import("./Main/news/NewsList"))
const NewsView = lazy(() => import("./Main/news/NewsView"))

const ThrowNotFound = () => {
  throw new NotFoundError()
}

export const routes: Route<DefaultGenerics>[] = [
  { path: "/logout", element: <Logout /> },
  {
    path: "/",
    id: "main",
    element: <Main />,
    children: [
      { path: "billing", element: <BillingAccountList /> },
      {
        path: "dataplanes/play/:dataplaneUuid/workflow-set/:workflowSetUuid",
        element: <WorkflowSetView />
      },
      {
        path: "dataplanes",
        element: <DataplaneList />,
        children: [
          { path: "/", element: <Navigate replace to="play" /> },
          {
            path: "play",
            children: [
              { path: "/", element: <PlayDataplaneList /> },
              { path: ":dataplaneName", element: <PlayDataplaneView /> }
            ]
          },
          {
            path: "link",
            children: [
              { path: "/", element: <LinkDataplaneList /> },
              { path: ":dataplaneName", element: <LinkDataplaneView /> }
            ]
          }
        ]
      },
      {
        path: "operators",
        children: [
          { path: "/", element: <OperatorList /> },
          { path: ":operatorUuid", element: <OperatorView /> },
          { element: <ThrowNotFound /> }
        ]
      },
      {
        path: "organizations",
        children: [
          { path: "/", element: <OrganizationList /> },
          {
            path: "/:orgUuid",
            children: [
              { path: "/api-keys/:apiKeyUuid", element: <ApiKeyView /> },
              { path: "/drm-providers/:drmProviderUuid", element: <DrmProviderView /> },
              {
                path: "/link/pools/:poolUuid/api-keys/:apiKeyUuid",
                element: <ApiKeyView />
              },
              {
                path: "/link/pools/:poolUuid",
                element: <LinkPoolView />,
                children: [
                  { path: "/", element: <LinkPropertiesTab /> },
                  {
                    path: "/inputs",
                    children: [
                      { path: "/", element: <LinkInputList /> },
                      { path: ":inputUuid", element: <LinkInputView /> },
                      { element: <ThrowNotFound /> }
                    ]
                  },
                  {
                    path: "/outputs",
                    children: [
                      { path: "/", element: <OutputList /> },
                      { path: ":outputUuid", element: <OutputView /> },
                      { element: <ThrowNotFound /> }
                    ]
                  },
                  { element: <ThrowNotFound /> }
                ]
              },
              {
                path: "/play/pools/:poolUuid/api-keys/:apiKeyUuid",
                element: <ApiKeyView />
              },
              {
                path: "/play/pools/:poolUuid",
                element: <PlayPoolView />,
                children: [
                  { path: "/", element: <PlayPropertiesTab /> },
                  {
                    path: "/inputs",
                    children: [
                      { path: "/", element: <InputsList /> },
                      { path: ":inputUuid/", element: <InputView /> },
                      { path: ":inputUuid/streams/:streamUuid", element: <StreamView /> },
                      { element: <ThrowNotFound /> }
                    ]
                  },
                  {
                    path: "/streams",
                    children: [
                      { path: "/", element: <StreamsList /> },
                      { element: <ThrowNotFound /> }
                    ]
                  },
                  {
                    path: "/processing",
                    children: [
                      { path: "/", element: <ProcessingsList /> },
                      { path: ":processingUuid", element: <ProcessingView /> },
                      { element: <ThrowNotFound /> }
                    ]
                  },
                  {
                    path: "/targets",
                    children: [
                      { path: "/", element: <TargetsList /> },
                      { path: ":targetUuid", element: <TargetView /> },
                      { element: <ThrowNotFound /> }
                    ]
                  },
                  { element: <ThrowNotFound /> }
                ]
              },
              {
                children: [
                  {
                    element: <OrganizationView />,
                    children: [
                      { path: "/", element: <OrganizationTab /> },
                      { path: "/billing", element: <BillingTab /> },
                      { path: "/link", element: <LinkTab /> },
                      { path: "/play", element: <PlayTab /> },
                      { path: "/powerVU", element: <PowerVUTab /> },
                      { element: <ThrowNotFound /> }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "organization-groups",
        children: [
          { path: "/", element: <OrganizationGroupList /> },
          { path: ":organizationGroupUuid", element: <OrganizationGroupView /> },
          { element: <ThrowNotFound /> }
        ]
      },
      {
        path: "teams",
        children: [
          { path: "/", element: <TeamList /> },
          { path: ":teamUuid", element: <TeamView /> },
          { element: <ThrowNotFound /> }
        ]
      },
      {
        path: "users",
        children: [
          { path: "/", element: <UserList /> },
          { path: ":userUuid", element: <UserView /> },
          { element: <ThrowNotFound /> }
        ]
      },
      {
        path: "cogs",
        element: <CogsView />,
        children: [
          { path: "/cost", element: <CostCogs /> },
          { path: "/usage", element: <UsageCogs /> },
          { path: "/orgs", element: <OrgsCogs /> },
          { element: <ThrowNotFound /> }
        ]
      },
      {
        path: "logs",
        element: <LogList />
      },
      {
        path: "news",
        children: [
          { path: "/", element: <NewsList /> },
          { path: ":newsUuid", element: <NewsView /> },
          { element: <ThrowNotFound /> }
        ]
      },
      { path: "/", element: <Navigate to="/organizations" /> },
      { element: <ThrowNotFound /> }
    ]
  },
  { element: <ThrowNotFound /> }
]
